import React, {
  useContext,
  useMemo,
} from 'react';

import { ReactComponent as LibcomIcon } from '../images/libcom-icon.svg';
import { ReactComponent as ArcoIcon } from '../images/arco-icon.svg';

import AppContext from '../contexts/AppContext';

function Header() {
  const { config } = useContext(AppContext);

  const content = useMemo(() => {
    switch (config.schema) {
      case 'arco': return <header><ArcoIcon /></header>;
      case 'libcom': return <header><LibcomIcon /></header>;

      default: return <></>;
    }
  }, [config.schema]);

  return content;
}

export default Header;
