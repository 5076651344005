import React, { 
  useRef, 
  useEffect, 
  useContext
} from 'react';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';

import AppContext from '../contexts/AppContext';

import Error from './Error';


function Form({ onSubmit }) {
  const { responseStatus, loading, inputCpf, setInputCpf } = useContext(AppContext);

  const validCpf = useRef(false);
  
  function submitHandle(event) {
    event.preventDefault();
    if (inputCpf) {
      onSubmit({validCpf: validCpf.current, inputCpf});
    } 
  }
  
  useEffect(() => {
    const reg = /[.-]/g;
    const numCpf = inputCpf.replace(reg, "");
    validCpf.current = (numCpf.length === 11) ? cpf.isValid(numCpf) : false;
  }, [inputCpf]);

  return (
    <section className="form-box">
      <form onSubmit={submitHandle}>
        <InputMask
          fullWidth
          label='Informe seu CPF'
          type='tel'
          mask='999.999.999-99'
          maskChar=' '
          onChange={(event) => setInputCpf(event.target.value)}
          value={inputCpf}
        >
          {(inputProps) => <TextField {...inputProps}/>}
        </InputMask>

        <Button
          fullWidth
          color="primary"
          variant='contained'
          onClick={submitHandle}>
          ENVIAR
        </Button>
      </form>
      {
        loading && (      
          <div className='loading'>
            <CircularProgress />
          </div>
        ) 
      }
      {
        (!loading && responseStatus !== 0) && <Error status={responseStatus} /> 
      }
    </section>
  );
}

export default Form;