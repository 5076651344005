import React, {
  useCallback, 
  useContext 
} from 'react';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AppContext from '../contexts/AppContext';

import Item from './Item';

function List() {
  const { result, setResponseStatus, setResult } = useContext(AppContext);

  const onArrowBack = useCallback(() => {
    setResponseStatus(0);
    setResult({});
  }, [setResponseStatus, setResult]);
  
  return (
    <section className='list'>
      <header id="list-header">
        <IconButton size="small" onClick={onArrowBack}>
          <ArrowBackIcon/>
        </IconButton>
      </header>
      <h3>Olá, {result.apelido}!</h3>

      {
        result.eventos.map((evento, index) =>
          <Item
            key={evento.id}
            url={evento.url}
            descricao={evento.descricao}
            idEvento={evento.id}
            dataInicial={evento.dataInicial}
            dataFinal={evento.dataFinal}
            idZoomEventoPessoa={evento.idZoomEventoPessoa}
            proximoEvento={index === 0}
          />
        )
      }
    </section>
  );
}


export default List;