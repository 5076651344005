/**
 * Transforma a primeira letra de uma palavra em maiuscula.
 * @param {string} str 
 * @returns {string}
 */
export function primeiraLetraMaiscula(str) {
  const char = str.charAt();
  const resto = str.substring(1);

  return `${char.toUpperCase()}${resto}`;
}

/**
 * Função que valida se uma string está no formato HTTP
 * @param {string} url
 * @returns {boolean}
 */
export function validaURL(url) {
  const urlInput = document.createElement('input');
  urlInput.type = 'url';
  urlInput.value = url;

  return urlInput.checkValidity();
}