import React from 'react';

import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { useContext, useCallback } from 'react';
import AppContext from '../contexts/AppContext';

export default function Error({ status }) {
  const { config } = useContext(AppContext);
  
  const { palette: { primary } } = useTheme();

  const { hash } = useParams();

  const onClickHere = useCallback(() => {
    const url = process.env.REACT_APP_APP_INSCRICAO
      .replace('infogab.com.br', config.domain);
    const path = hash
      ? `/s2/${hash}`
      : '/';
    
    window.open(url + path + '?irparaevento=true', '_self');
  }, [config.domain, hash]);

  return (
    <div className="aviso" style={{ color: primary.main }}>
      {
        status === 200
          ? (
            <span>
              <b>Nenhum evento encontrado para hoje.</b>
              {
                config.schema !== 'libcom' && (
                  <>
                    <br /><br />
                    Se você foi convidado a um evento, atualize seu cadastro.
                    <Button onClick={onClickHere}>
                      Seguir para cadastro
                    </Button>
                  </>
                )
              }
            </span>
          )
          : <span><b>CPF inválido.</b></span>
      }
    </div>
  );
}