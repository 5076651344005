import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { AppContextProvider } from './contexts/AppContext';

import App from './App';

import './index.css';

function setTheme(palette) {
  return createMuiTheme({
    palette,
    typography: {
      fontSize: 16,
      '@media (min-width:600px)': {
        fontSize: 10,
      },
    },
  });
};

ReactDOM.render(
  <AppContextProvider>
    {
      ({ config }) =>
        <ThemeProvider theme={setTheme(config.palette)}>
          <BrowserRouter>
            <Switch>
              <Route path="(/eqp|/cf|/regiao)?" exact component={App} />
              <Route path="/:hash([0-9]{4}[a-z]{2}.*)" exact component={App} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
    }
  </AppContextProvider>,
  document.getElementById('root')
);