/* Paleta de cores */
// enrico e rogerio
const paletteEMRS = {
  primary: {
    main: '#009384',
    light: '#02af71',
  },
};
// reinaldo e tania
const paletteRATF = {
  primary: {
    main: '#00749F',
    light: '#00acea',
  },
}

const paletteINFO = {
  primary: {
    main: '#303f9f',
    light: 'rgba(0, 255, 255)'
  },
}

export default function setConfig() {
  const [domain] = (
    window.location.hostname
      .match(/((reinaldoalguz|enricomisasi|rogeriossantos|taniafruchi|luizalbertoramos)\.com|(arco|libcom)\.org)\.br/i)
      || ['infogab.com.br']
  );

  switch (domain) {
    case 'reinaldoalguz.com.br':
      return {
        domain,
        schema: 'z00001ra',
        palette: paletteRATF,
      }

    case 'enricomisasi.com.br':
      return {
        domain,
        schema: 'z01453em',
        palette: paletteEMRS,
      };

    case 'rogeriossantos.com.br':
      return {
        domain,
        schema: 'z00254js',
        palette: paletteEMRS,
      };

    case 'taniafruchi.com.br':
      return {
        domain,
        schema: 'z03843tf',
        palette: paletteRATF,
      };

    case 'luizalbertoramos.com.br':
      return {
        domain,
        schema: 'z03887ls',
        palette: paletteINFO,
      }

    case 'libcom.org.br':
      return {
        domain,
        schema: 'libcom',
        palette: {
          primary: {
            main: '#e2bb6d',
            light: 'rgb(255,255,0)',
          },
        }
      };

    case 'arco.org.br':
      return {
        domain,
        schema: 'arco',
        palette: {
          primary: {
            main: '#007b96',
            light: '#00B17D'
          },
        }
      };

    default:
      return {
        domain, // infogab.com.br
        schema: 'default',
        palette: paletteINFO
      };
  }
}
