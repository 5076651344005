import React, { createContext, useMemo, useState } from 'react';

import setConfig from '../util/config';

/**
 * @typedef {object} AppContextResources
 * @prop {{ schema: string, domain: string, palette: ReturnType<typeof setConfig>['palette'] }} config
 * @prop {string} inputCpf
 * @prop {boolean} loading
 * @prop {number} responseStatus
 * @prop {any} result
 * @prop {React.Dispatch<React.SetStateAction<string>>} setInputCpf
 * @prop {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @prop {React.Dispatch<React.SetStateAction<any>>} setResult
 * @prop {React.Dispatch<React.SetStateAction<number>>} setResponseStatus
 */

/** @type {React.Context<AppContextResources>} */
const AppContext = createContext();

export function AppContextProvider(props) {
  const config = setConfig();

  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(0);
  const [ inputCpf, setInputCpf ] = useState('');

  const value = useMemo(() => ({
    config,
    inputCpf,
    loading,
    responseStatus,
    result,
    setInputCpf,
    setLoading,
    setResult,
    setResponseStatus
  }), [config, inputCpf, loading, responseStatus, result]);

  return (
    <AppContext.Provider value={value}>
      {props.children({ config })}
    </AppContext.Provider>
  )
}

export default AppContext;