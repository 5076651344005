import React, {
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import axios from 'axios';
import { cpf } from 'cpf-cnpj-validator';

import AppContext from './contexts/AppContext';

import Form from './components/Form';
import Header from './components/Header';
import List from './components/List';

import './App.css';
import { useLocation } from 'react-router-dom';

function App() {
  const {
    config,
    loading,
    responseStatus,
    result,
    setInputCpf,
    setLoading,
    setResponseStatus,
    setResult
  } = useContext(AppContext);

  const { search } = useLocation();

  const onFormSubmit = useCallback(async (body) => {
    if (body.validCpf) {
      try {
        setLoading(true);

        const request = axios.create({
          baseURL: process.env.REACT_APP_API_EVENTOS,
          headers: { app: 6 },
          withCredentials: false,
        })

        const checkSchema = config.schema !== 'default';

        const urlObterLinkEvento = checkSchema
          ? `/obter-link-evento-zoom?cpf=${body.inputCpf}`
          : `/pessoas/obter-link-evento?cpf=${body.inputCpf}`;

        if (checkSchema)
          request.defaults.headers.schema = config.schema;

        const response = await request.get(urlObterLinkEvento);

        if (response.status === 200) {
          setResponseStatus(200);
          setResult(response.data);
        } else if (response.status === 400) {
          setResponseStatus(400);
        }
      }
      catch (error) {
        setResponseStatus(500);
      }
      finally {
        setLoading(false);
      }
    } else setResponseStatus(400);
  }, [config.schema, setLoading, setResponseStatus, setResult]);

  useEffect(() => {
    const searchCpf = new URLSearchParams(search).get('cpf') || '';
    const digits = searchCpf.replace(/\D/g, '');

    if (searchCpf && digits.length === 11 && cpf.isValid(digits)) {
      const cpf = Array.from(digits)
        .reduce((total, digit) => total.replace('X', digit), 'XXX.XXX.XXX-XX');
      setInputCpf(cpf);
      onFormSubmit({ validCpf: true, inputCpf: cpf });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = useMemo(() => {
    /* Form */
    if (responseStatus === 0 || !result.eventos) {
      return <Form onSubmit={onFormSubmit} />
    }

    /* Response */
    if (!loading && result.eventos) {
      return <List />
    }


  }, [loading, onFormSubmit, responseStatus, result]);

  return (
    <main className="App">

      {/* Header */}
      <Header />

      {content}

    </main>
  );
}

export default App;
