const semana = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feira',
  'quinta-feira',
  'sexta-feira',
  'sábado',
];

export class Data extends Date {
  /**
   * Formatação da data para o formato: DD/MM/YYYY,
   * separando os componentes de dia, mes e ano
   * @returns Componentes de data
   */
  formatarComponentesData() {
    const { ano, mes, dia } = this.obterData();

    const mesFormatado = (mes + 1).toLocaleString('pt-br', { minimumIntegerDigits: 2 });
    const diaFormatado = dia.toLocaleString('pt-br', { minimumIntegerDigits: 2 });

    return {
      ano,
      mes: mesFormatado,
      dia: diaFormatado,
    }
  }

  /**
   * Formatação da horario para o formato: HH:MM:SS,
   * separando os componentes de hora, minutos e segundos
   * @returns Componentes de data
   */
   formatarComponentesHorario() {
    const { hora, mins, secs } = this.obterHorario();

    const horaFormatado = hora.toLocaleString('pt-br', { minimumIntegerDigits: 2 });
    const minsFormatado = mins.toLocaleString('pt-br', { minimumIntegerDigits: 2 });
    const secsFormatado = secs.toLocaleString('pt-br', { minimumIntegerDigits: 2 });

    return {
      hora: horaFormatado,
      mins: minsFormatado,
      secs: secsFormatado,
    }
  }

  /**
   * Função que retorna o dia da semana em lowercase.
   * @returns Nome do dia da semana
   */
  obterDiaDaSemana() {
    const weekDayIndex = this.getDay();
    return semana[weekDayIndex];
  }

  /**
   * Função que retorna os componentes da data
   * @returns Componentes da data
   */
  obterData() {
    return {
      ano: this.getFullYear(),
      mes: this.getMonth(),
      dia: this.getDate(),
    }
  }

  /**
   * Função que retorna os componentes do horário
   * @returns Componentes do horario
   */
  obterHorario() {
    return {
      hora: this.getHours(),
      mins: this.getMinutes(),
      secs: this.getSeconds()
    }
  }
}