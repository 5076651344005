import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import axios from 'axios';

import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppContext from '../contexts/AppContext';

import { Data } from '../util/Data';
import { primeiraLetraMaiscula, validaURL } from '../util';
import { useLocation } from 'react-router-dom';

const hoje = new Data();
const { ano: anoAtual, mes: mesAtual, dia: diaAtual } = hoje.obterData();
const timestampDataAtual = Date.UTC(anoAtual, mesAtual, diaAtual)

const eventoSemLinkValido = 'Verifique a causa com os responsáveis pelo evento';
const acessoAoEventoIndisponivel = 'Você poderá acessar o evento até uma hora antes do horário marcado';

function Item({ idEvento, url, descricao, dataInicial, dataFinal, idZoomEventoPessoa, proximoEvento }) {
  const { config, result } = useContext(AppContext);

  const { palette: { primary } } = useTheme();

  const { pathname } = useLocation();

  const urlValida = validaURL(url);

  const [loading, setLoading] = useState(false);

  const nome = result.nomeExibicao || result.apelido;
  const siglas = useMemo(() => {
    // verifica o parâmetro
    const isEquipe = (pathname === '/eqp');
    const isCf = (pathname === '/cf');
    const idTipoPerfil = (isEquipe ? 9 : isCf ? 8 : null);

    // monta lista de perfis
    const listaPerfis = result.perfis && result.perfis.filter(perfil => perfil.tipo === idTipoPerfil);
    const listaSiglas = listaPerfis && listaPerfis.map(perfil => perfil.sigla)

    // baseado no parâmetro, retorna string com perfis
    return listaSiglas && listaSiglas.length ? `${listaSiglas.join('/')} - ` : '';
  }, [pathname, result.perfis]);


  const inicioEvento = dataInicial.replace('Z', '');
  const fimEvento = dataFinal.replace('Z', '');
  const urlEvento = useMemo(() => {
    if (url && urlValida) {
      const evento = new URL(url);

      const participanteCidade = result.cidade && result.cidade.toUpperCase();
      const participanteRegiao = (result.regiao ? result.regiao.toUpperCase().substring(4) : 'Sem Região');

      const dadoComplementar = (pathname === '/regiao' ? participanteRegiao : participanteCidade)
      const nomeDeExibicao = (config.schema === 'default')
        ? `${siglas}${nome} - ${dadoComplementar}`
        : nome.trim();
      evento.searchParams.append('uname', nomeDeExibicao);

      return evento.href;
    }
  }, [
    config.schema,
    nome,
    pathname,
    result.cidade,
    result.regiao,
    siglas,
    url,
    urlValida,
  ]);

  const dataFormatada = useMemo(() => {
    const objData = new Data(inicioEvento);

    const { ano, mes, dia } = objData.obterData();
    const timestamp = Date.UTC(ano, mes, dia);

    // pega a diferenca de tempo entre a data a atual e do evento,
    // se não houver diferença o evento é hoje senão usa o dia da semana
    const diff = timestamp - timestampDataAtual;
    const diaDaSemana = (diff === 0) ? 'Hoje' : objData.obterDiaDaSemana();
    const data = objData.formatarComponentesData();
    const horario = objData.formatarComponentesHorario();

    return {
      ano: data.ano,
      mes: data.mes,
      dia: data.dia,
      diaDaSemana: primeiraLetraMaiscula(diaDaSemana),
      hora: horario.hora,
      mins: horario.mins,
      timestamp,
    };
  }, [inicioEvento]);

  /* Estado que monitora o acesso ao evento */
  const [irParaEvento, setIrParaEvento] = useState(() => {
    // Não é data do evento
    if (dataFormatada.timestamp - timestampDataAtual > 0)
      return false;

    const agora = new Data().getTime();
    const evento = new Data(inicioEvento).getTime();

    // Verifica se já é uma hora antes do horario.
    return evento - agora <= (60 * 60000);
  });

  /*
    Cria um intervalo de monitoramento
    para atualizar o estado caso falte 1 hora para o inicio do evento
  */
  useEffect(() => {
    // Se o acesso já estiver liberado, mantem
    if (irParaEvento)
      return;
    // Caso contrario define intervalo para enquanto o componente estiver renderizado

    // Monitoramento de acesso ao evento
    // A cada um minuto verifica se falta uma hora para o evento.
    let id;
    const proxMinuto = 60000 - (new Date().getTime() % 60000);
    // Timeout sincroniza intervalo com relógio
    window.setTimeout(() => {
      function verificaAcesso() {
        const agora = new Data().getTime();
        const evento = new Data(inicioEvento).getTime();

        const valor = (evento - agora) <= (60 * 60000);

        setIrParaEvento(antigo => {
          if (valor !== antigo) {
            window.clearInterval(id);
            return valor;
          }

          return antigo;
        });
      }

      id = window.setInterval(verificaAcesso, 60000);
      verificaAcesso();
    }, proxMinuto);

    // Ao desmontar componente limpar intervalo
    return () => window.clearInterval(id);
  }, [irParaEvento]); // eslint-disable-line

  function AdicionarGoogleAgenda() {
    const calendar = new URL('https://calendar.google.com/calendar/u/0/r/eventedit');

    const inicioTimestamp = inicioEvento.replace(/\W/g, '');
    const fimTimestamp = fimEvento.replace(/\W/g, '');

    const dataEvento = `${inicioTimestamp}/${fimTimestamp}`;

    calendar.searchParams.append('text', descricao);
    calendar.searchParams.append('dates', dataEvento);

    if (urlEvento) {
      calendar.searchParams.append('location', urlEvento);
      calendar.searchParams.append('details', `Para entrar no evento, acesse ${urlEvento}`);
    }

    window.open(calendar.href);
  }

  async function onIrParaEventoClick() {
    const response = await alterarStatusParticipante();
    if (response) {
      window.location.href = urlEvento;
    }
  }

  async function alterarStatusParticipante() {
    try {
      let path;
      let body;

      setLoading(true);

      const request = axios.create({
        baseURL: process.env.REACT_APP_API_EVENTOS,
        headers: { app: 6, schema: config.schema },
      });

      if (!config.schema.match(/arco|libcom/i)) {
        path = '/pessoas/altera-status-evento-reuniao';
        body = { idPessoa: result.idPessoa, idEvento };
        await request.post(path, body);
      }

      path = '/altera-status-evento-zoom';
      body = `idZoomEventoPessoa=${idZoomEventoPessoa}`;
      request.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      await request.post(path, body);

      setLoading(false);
      return true;
    }
    catch (error) {
      setLoading(false);
      return false;
    }
  }

  return (
    <article>
      <h1>
        {
          proximoEvento
            ? 'Seu próximo evento será'
            : 'Você terá um evento'
        }
      </h1>
      <h2>
        {dataFormatada.diaDaSemana}, <br />
        {dataFormatada.dia}/{dataFormatada.mes}
        {' às '}
        {dataFormatada.hora}:{dataFormatada.mins}
      </h2>

      {loading && <CircularProgress />}

      {!loading &&
        <>
          <span style={{ paddingBottom: 20 }} title={!urlEvento ? eventoSemLinkValido : acessoAoEventoIndisponivel}>
            <Button
              disabled={!urlEvento || !irParaEvento}
              variant='contained'
              fullWidth
              color="primary"
              onClick={onIrParaEventoClick}
            >
              {
                !urlEvento
                  ? urlValida ? 'EVENTO SEM LINK' : 'URL DO EVENTO INVÁLIDA'
                  : 'IR PARA EVENTO'
              }
            </Button>
          </span>
          <span
            className="botao-google-agenda"
            onClick={AdicionarGoogleAgenda}
            style={{ color: primary.main }}
          >
            Adicionar ao Google Agenda
          </span>
        </>
      }
    </article>
  );
};

export default Item;